// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // URL_SERVICE: 'http://localhost:8080/WSRestAPIFebms/EBSFebms/',
  // URL_SERVICE:'http://localhost:9090/WSRestAPIPinc/EBSPINC/',

  //  URL_SERVICE: 'http://localhost:8080/WSRestAPIFonmead/EBSFONMEAD/',
   //URL_SERVICE: 'http://198.38.85.158:8081/WSRestAPIFonmead/EBSFONMEAD/',
   //URL_SERVICE: 'https://apps.ebscreditodigital.com/WSRestAPIFonmead/EBSFONMEAD/',
   //URL_SERVICE: 'https://ebsappweb.com:8443/WSRestAPIFonmead/EBSFONMEAD/',
  URL_SERVICE: 'https://febmspincbe.ebscreditodigital.com/WSRestAPIFebms/EBSFebms/',
  
  //URL_SERVICE: 'http://localhost:8080/WSRestAPIFonmead/EBSCOOLEVER/',
  // URL_SERVICE: 'http://localhost:8080/WSRestAPIPinc/EBSPINC/',
  // URL_SERVICE: 'http://localhost:9090/WSRestAPIProgresemos/EBSPINC/',
  URL_FTP: 'https://apps.ebscreditodigital.com/Fonmead/'


// Construir para pruebas
  // ng build --output-hashing=all --prod --base-href /Progresemos/


  // URL_FTP: 'http://localhost:9090/WSRestAPIProgresemos/',


  // URL_SERVICE:'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
  // URL_FTP: 'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
